import showAjaxMessage from "../packs/xhr_flash_errors"

function checkSignUpAgreements() {
  const button = document.getElementById('js-agree-enabled-button');
  if (button) {
    const tosAndPpCheckBox = document.getElementById('js-accepted-tos-and-pp');

    const enableButton = function() {
      if(tosAndPpCheckBox.checked) {
        button.removeAttribute('disabled')
      } else {
        button.setAttribute('disabled', 'disabled')
      }
    }

    tosAndPpCheckBox.addEventListener('change', enableButton, false);
    enableButton();
  }
}

function loadCountriesList() {
  const countriesSelect =  document.getElementById('js-country-select');
  if(!countriesSelect) {
    return false;
  }

  fetchCountriesAndPreselect(countriesSelect);
}

function loadPhoneCountryCodesList() {
  const countriesSelect =  document.getElementById('js-phone-number-country-code-select');
  if(!countriesSelect) {
    return false;
  }

  fetchCountriesAndPreselect(countriesSelect);
}

function fetchCountriesAndPreselect(countriesSelect) {
  Rails.ajax({
    url: countriesSelect.getAttribute('data-url'),
    type: 'get',
    dataType: 'json',
    success: (data, statusText, xhr) => {
      countriesSelect.innerHTML = countriesSelect.innerHTML + data.options
      // the header added by CDN edge function (CloudFront)
      const viewerCountryCode = xhr.getResponseHeader('X-237-Global-Viewer-Country');
      const selectedCountryCode = countriesSelect.getAttribute('data-selected-country-code');
      if (selectedCountryCode) {
        countriesSelect.value = selectedCountryCode;
      } else if (viewerCountryCode) {
        countriesSelect.value = viewerCountryCode;
      } else {
        countriesSelect.value = 'US';
      }
    }
  });
}

export function initWebSignUp() {
  const webSignUpForm = document.getElementById('js-web-sign-up-form')
  if(!webSignUpForm) {
    return false;
  }
  checkSignUpAgreements();
  loadCountriesList();
  loadPhoneCountryCodesList();
}
