function showAjaxMessage(msg, type) {
  const error_types = {
    notice: 'success',
    alert: 'danger'
  };

  document.getElementById('flashContainer').innerHTML =
    `<div class='alert alert-${error_types[type]}' role='alert'>
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      ${msg}
    </div>`;
}

document.addEventListener('ajax:complete', (event) => {
  const msg = event.detail[0].getResponseHeader('X-Message');
  const type = event.detail[0].getResponseHeader('X-Message-Type');
  if(typeof(msg) === 'string') {
    showAjaxMessage(msg, type)
  }
});

export default showAjaxMessage
