import { Controller } from "stimulus"
import { getCookie } from "../../helpers/cookies_helper"

export default class CookieBannerController extends Controller {
  static targets = ["banner"]

  connect() {
    this.checkCookieBannerStatus()
  }
  
  accept() {
    this.hideBanner()
    this.acceptCookieBanner()
  }
  
  hideBanner() {
    this.bannerTarget.style.display = "none"
  }

  acceptCookieBanner() {
    const expiryDate = new Date()
    expiryDate.setFullYear(expiryDate.getFullYear() + 1) // Set the cookie to expire in 1 year
    document.cookie = `cookie_notice=accepted; expires=${expiryDate.toUTCString()}; path=/`
  }

  checkCookieBannerStatus() {
    const cookieBanner = this.getCookieBannerStatus()
    if (cookieBanner) {
      this.hideBanner()
    }
  }
  
  getCookieBannerStatus() {
    const cookieValue = getCookie("cookie_notice")
    return cookieValue === "accepted"
  }
}
